<template>
  <div class="relative min-h-screen">
    <div v-if="mode == 'newQuote'">
      <div class=" mb-3">
        <div v-if="loading.quote" class="text-center mt-2 mb-2">
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
        </div>
        <div
          class=" w-full ml-auto mr-auto md:w-8/12 bg-white  p-6 rounded-lg  mb-3 md:mb-6  "
        >
          <h1 class="font-bold text-lg text-center mb-2 text-darkblue">
            {{ $route.params.mode == "edit" ? "Update" : "New" }} Quote
          </h1>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form ref="quoteForm" @submit.prevent="handleSubmit(SubmitQuote)">
              <div class="mb-4">
                <label class="block  md:text-xs " for="">
                  Client's Name
                </label>
                <validation-provider
                  rules="required|alpha_spaces"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="Client's Name"
                    v-model="client.clientName"
                    class="border-2 w-full border-darkblue  md:text-xs  rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="mb-4">
                <label class="block  md:text-xs " for=""
                  >Client's Phone Number</label
                >
                <validation-provider
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="client.clientPhone"
                    type="text"
                    name="Phone Number"
                    class="border-2 w-full  md:text-xs  border-darkblue rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="mb-4">
                <label class="block  md:text-xs " for="">
                  Client's Email
                </label>
                <validation-provider rules="email" v-slot="{ errors }">
                  <input
                    type="email"
                    name="Email"
                    v-model="client.clientEmail"
                    class="border-2 w-full  md:text-xs  border-darkblue rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="mb-4">
                <label class="block  md:text-xs " for="">
                  Client's Address
                </label>
                <validation-provider rules="address" v-slot="{ errors }">
                  <input
                    v-model="client.clientAddress"
                    type="text"
                    name="Address"
                    class="border-2 w-full  md:text-xs  border-darkblue rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="mb-4">
                <label class="block  md:text-xs " for="">
                  VAT (in %)
                </label>
                <validation-provider
                  rules="double|min_value:0.1"
                  v-slot="{ errors }"
                >
                  <input
                    v-model="vat"
                    type="text"
                    placeholder="E.g 7.5"
                    name="V.A.T"
                    class="border-2 w-full  md:text-xs  border-darkblue rounded-lg block"
                  />
                  <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <div v-if="tableData.length > 0" class="mt-6 mb-3 overflow-auto">
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="tableData"
        >
          <template slot="column" slot-scope="props">
            <td v-if="props.text === 'action'" :class="props.tdClass">
              <button
                @click="SetEditQuoteItemValues(props.rowIndex)"
                class="  mr-2 "
              >
                <font-awesome-icon
                  title="Edit"
                  :class="'text-darkblue hover:text-black text-sm'"
                  :icon="['fas', 'pen']"
                />
              </button>
              <button
                @click="DeleteQuoteItem(props.rowIndex)"
                class="   focus:bg-gray-100"
              >
                <font-awesome-icon
                  title="Delete"
                  :class="'text-red-600 hover:text-black text-sm'"
                  :icon="['far', 'trash-alt']"
                />
              </button>
            </td>
            <td v-else :class="props.tdClass">{{ props.text }}</td>
          </template>
        </t-table>
      </div>
      <div class="text-center">
        <button
          @click="mode = 'newQuoteItem'"
          type="button"
          class="text-darkblue mr-2  hover:bg-darkblue border-2 border-darkblue  md:text-xs mb-3  mt-3 hover:text-white  pl-3 pt-1 pb-1 pr-3  rounded-full"
        >
          Add Quote Item
        </button>
        <button
          @click="$refs.quoteForm.requestSubmit()"
          type="button"
          class="text-white  md:text-xs mb-3  mt-3  bg-green-600 hover:bg-darkblue pl-3 pt-2 pb-2 pr-3  rounded-full"
        >
          <svg
            v-if="loadingSpinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{ $route.params.mode != "edit" ? "Save Quote" : "Update Quote" }}
        </button>
      </div>
    </div>

    <div v-if="mode == 'newQuoteItem'">
      <div
        class="bg-white md:w-8/12 w-10/12 mt-5 mr-auto ml-auto p-6 mb-4 rounded-lg"
      >
        <h1 class="font-bold text-lg text-center text-darkblue">
          Add Quote Item
        </h1>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            ref="quoteItemForm"
            @submit.prevent="handleSubmit(AddQuoteItem)"
          >
            <div class="mb-4">
              <label class="block  md:text-xs " for="Selling price"
                >Description</label
              >
              <validation-provider rules="required|address" v-slot="{ errors }">
                <input
                  v-model="selectedQuote.description"
                  type="text"
                  name="Description"
                  class="border-2 w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label class="block  md:text-xs " for="Selling price"
                >Material</label
              >
              <validation-provider rules="required|address" v-slot="{ errors }">
                <input
                  v-model="selectedQuote.material"
                  type="text"
                  name="Material"
                  class="border-2 w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label class="block  md:text-xs " for="Selling price"
                >Unit
              </label>
              <validation-provider rules="required|alpha" v-slot="{ errors }">
                <input
                  v-model="selectedQuote.unit"
                  type="text"
                  name="Unit"
                  class="border-2 w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label class="block  md:text-xs " for="Selling price"
                >Unit Price(₦)</label
              >
              <validation-provider
                rules="required|numeric|min_value:1"
                v-slot="{ errors }"
              >
                <input
                  v-model="selectedQuote.unitPrice"
                  type="number"
                  name="Unit price"
                  class="border-2 w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4">
              <label class="block  md:text-xs " for="Selling price"
                >Quantity</label
              >
              <validation-provider
                rules="required|numeric|min_value:1"
                v-slot="{ errors }"
              >
                <input
                  v-model="selectedQuote.quantity"
                  type="number"
                  name="Quantity"
                  class="border-2 w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <p class="text-center mb-2 mt-2 text-sm">
              Item Total(₦) : {{ selectedQuoteTotal.toLocaleString() }}
            </p>
            <div class="text-center">
              <button
                @click="mode = 'newQuote'"
                type="button"
                class="text-white mb-3  mr-2  md:text-xs  bg-red-600 hover:bg-black pl-3 pt-2 pb-2 pr-3  rounded-full"
              >
                Go back to Quote
              </button>
              <button
                type="submit"
                class="text-white mb-3   md:text-xs  bg-darkblue hover:bg-black pl-3 pt-2 pb-2 pr-3  rounded-full"
              >
                {{
                  quoteItemMode == "new"
                    ? "Add Quote Item"
                    : "Update Quote Item"
                }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageQuote",
    props: {},
    components: { ValidationProvider, ValidationObserver, FontAwesomeIcon },
    apollo: {
      quote: {
        query: gql`
          query quote($id: ID!) {
            quote(id: $id) {
              clientName
              clientEmail
              clientPhone
              clientAddress
              vatPercentage
              quoteItems {
                description
                quantity
                material
                unit
                unitPrice
              }
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.quote = true;
            return;
          }

          this.loading.quote = false;
        },
      },
    },
    data() {
      return {
        quote: {},
        vat: 0,
        mode: "newQuote",
        loadingSpinner: false,
        quoteItemMode: "new",
        loading: {
          quote: false,
        },
        quoteItemEditIndex: null,
        getStock: [],
        client: {
          clientName: "",
          clientPhone: "",
          clientEmail: "",
          clientAddress: "",
        },
        selectedQuote: {
          description: "",
          material: "",
          unit: "",
          unitPrice: 0,
          quantity: 0,
        },

        icons: {
          pen: require("@/assets/imgs/icons/pen.svg"),
          trash: require("@/assets/imgs/icons/trash.svg"),
        },

        tableClasses: {
          table:
            "min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 border-darkblue ",
          theadTr: " md:text-xs ",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "rounded-lg  md:text-xs  text-xs ",
          td: "px-3 py-2 whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        tableHeader: [
          {
            id: "itemName",
            value: "description",
            text: "Description",
            className: "",
          },
          {
            value: "material",
            text: "Material",
            className: "fortunate-table",
          },

          {
            value: "unitPrice",
            text: "Rate(₦)",
            className: "fortunate-table",
          },
          {
            value: "quantity",
            text: "Quantity",
            className: "fortunate-table",
          },
          {
            value: "unit",
            text: "Unit",
            className: "fortunate-table",
          },
          {
            value: "itemTotal",
            text: "Total(₦)",
            className: "fortunate-table",
          },
          {
            value: "action",
            text: "Action",
            className: "fortunate-table",
          },
        ],
        tableData: [],
      };
    },
    computed: {
      selectedQuoteTotal: function() {
        return (
          Number(this.selectedQuote.quantity) *
          Number(this.selectedQuote.unitPrice)
        );
      },
    },
    watch: {
      mode: function(newValue) {
        if (newValue == "newQuote") {
          window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
          );
          return;
        }
        window.scrollTo(0, 0);
      },
      quote: {
        handler: function(quote) {
          this.client = {
            clientName: quote.clientName,
            clientPhone: quote.clientPhone,
            clientEmail: quote.clientEmail,
            clientAddress: quote.clientAddress,
          };
          this.vat = quote.vatPercentage;
          this.tableData = quote.quoteItems.map((quoteItem) => {
            return {
              description: quoteItem.description,
              material: quoteItem.material,
              unit: quoteItem.unit,
              unitPrice: quoteItem.unitPrice,
              quantity: quoteItem.quantity,

              itemTotal: (
                Number(quoteItem.quantity) * Number(quoteItem.unitPrice)
              ).toLocaleString(),
              action: "action",
            };
          });
        },
        deep: true,
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      DeleteQuoteItem(index) {
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,

          message: `${this.tableData[index].description} has been deleted from quote items`,
        });
        this.tableData.splice(index, 1);
      },
      SetEditQuoteItemValues(index) {
        this.selectedQuote = {
          description: this.tableData[index].description,
          material: this.tableData[index].material,
          unitPrice: this.tableData[index].unitPrice,
          unit: this.tableData[index].unit,
          quantity: this.tableData[index].quantity,
        };
        this.quoteItemEditIndex = index;

        this.quoteItemMode = "edit";
        this.mode = "newQuoteItem";
      },
      EditQuoteItem() {
        this.$set(this.tableData, this.quoteItemEditIndex, {
          ...this.selectedQuote,
          itemTotal: this.selectedQuoteTotal,
          action: "action",
        });
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,
          message: `The quote item has been updated`,
        });
        this.mode = "newQuote";
      },

      SubmitQuote() {
        if (this.tableData.length < 1) {
          this.$emit("show-alert", {
            type: "error",
            message: `You need to add quote items before you can save`,
          });

          return;
        }
        if (this.$route.params.mode == "edit") {
          return this.UpdateQuote();
        }
        this.CreateQuote();
      },
      async CreateQuote() {
        this.loadingSpinner = true;

        const quoteItems = this.tableData.map((quoteItem) => {
          return {
            description: quoteItem.description,
            material: quoteItem.material,
            unit: quoteItem.unit,
            unitPrice: Number(quoteItem.unitPrice),
            quantity: Number(quoteItem.quantity),
          };
        });
        const quoteData = {
          clientName: this.client.clientName,
          clientPhone: this.client.clientPhone,
          clientEmail: this.client.clientEmail,
          clientAddress: this.client.clientAddress,
          quoteItems: quoteItems,
          ...(!!this.vat && { vatPercentage: Number(this.vat) }),
        };
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($quote: QuoteInput!) {
                createQuote(quote: $quote) {
                  _id
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              quote: quoteData,
            },
          });

          this.loadingSpinner = false;

          if (!errors && !!data.createQuote._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Quote created successfully`,
            });

            return this.$router.push("/app/quotes");
          }

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating quote,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating quote,check your network or contact your developer`,
          });
        }
      },
      async UpdateQuote() {
        this.loadingSpinner = true;

        const quoteItems = this.tableData.map((quoteItem) => {
          return {
            description: quoteItem.description,
            material: quoteItem.material,
            unit: quoteItem.unit,
            unitPrice: Number(quoteItem.unitPrice),

            quantity: Number(quoteItem.quantity),
          };
        });
        const quoteData = {
          clientName: this.client.clientName,
          clientPhone: this.client.clientPhone,
          clientEmail: this.client.clientEmail,
          clientAddress: this.client.clientAddress,
          ...(!!this.vat && { vatPercentage: Number(this.vat) }),

          quoteItems: quoteItems,
        };
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $quote: QuoteInput!) {
                updateQuote(id: $id, quote: $quote) {
                  _id
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              quote: quoteData,
            },
          });

          this.loadingSpinner = false;

          if (!errors && !!data.updateQuote._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Quote updated successfully`,
            });

            return this.$router.push("/app/quotes");
          }

          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating quote,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating quote,check your network or contact your developer`,
          });
        }
      },

      AddQuoteItem() {
        if (this.quoteItemMode != "new") {
          return this.EditQuoteItem();
        }
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,
          message: `${this.selectedQuote.description} has been added to quote items`,
        });
        this.tableData.push({
          description: this.selectedQuote.description,
          material: this.selectedQuote.material,
          unit: this.selectedQuote.unit,
          unitPrice: this.selectedQuote.unitPrice,
          quantity: this.selectedQuote.quantity,

          itemTotal: this.selectedQuoteTotal,
          action: "action",
        });

        this.selectedQuote = {
          description: "",
          material: "",
          unitPrice: 0,
          quantity: 0,
        };

        this.mode = "newQuote";
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Quotes", pathName: "quotes" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Quote",
                pathName: "single_quote",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Quote",
          pathName: "manage_quotes",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
